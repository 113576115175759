html {
    background-image: url(/images/website-background-6.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
body {
    background-color: transparent !important;
}
body > .container {
    background-color: rgba(255,255,255,0.9);
    padding-left:30px;
    padding-right:30px;
}
.monster-image, .character-image {
    max-width: 80px;
    background: #ccc;
    border: solid 1px #000;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    margin-left:auto;
    margin-right:auto;
}

.boss-image {
    width:100%;
    max-width: 480px;
    background: #ccc;
    border: solid 1px #000;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    margin-left:auto;
    margin-right:auto;
}

.root-element .remove-row {
    display:none;
}
.battle-content p {
    padding: 20px 0;
}
.versus {
    padding-top:20px;
    font-size: 20px;
}
.paradigms-content .paradigm {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    margin: 20px auto;
    padding: 10px 0 0 0;
    font-size: 22px;
}
.paradigm .row {
    margin-top:10px;
    margin-left:0;
    margin-right:0;
}
.paradigm .COM {
    background-color: #e38085;
}
.paradigm .RAV {
    background-color: #9392d6;
}
.paradigm .SEN {
    background-color: #fbf16b;
}
.paradigm .SYN {
    background-color: #b363f8;
}
.paradigm .MED {
    background-color: #80db7b;
}
.paradigm .SAB {
    background-color: #b0b0b0;
}
.paradigm .row .col-xs-4,
.paradigm .row .col-xs-6,
.paradigm .row .col-xs-12 {
    border-right: 1px solid #CCCCCC;
}
.paradigm .row .col-xs-4:first-child,
.paradigm .row .col-xs-6:first-child,
.paradigm .row .col-xs-12 {
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.paradigm .row .col-xs-4:last-child,
.paradigm .row .col-xs-6:last-child,
.paradigm .row .col-xs-12 {
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0;
}
.boss .characters, .battle .character {
    font-size:22px;
}
.boss .boss {
    font-size:34px;
}
.boss .characters img {
    margin-top:115px;
}
.boss .versus {
    padding-top:135px;
    font-size: 20px;
}
.battle-title, .boss-title, .chapter-title {
    padding-top: 30px;
    padding-bottom: 20px;
}
.dropdown-menu .search-input {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: nowrap;
}
.battle .character-image {
    margin: 10px auto;
}
.battle .monster-image {
    margin: 10px auto;
}


.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
}
.dropdown-submenu:hover>.dropdown-menu {
    display:block;
}
.dropdown-submenu>a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:5px;
    margin-right:-10px;
}
.dropdown-submenu:hover>a:after {
    border-left-color:#ffffff;
}
.dropdown-submenu.pull-left {
    float:none;
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left:-100%;
    margin-left:10px;
    -webkit-border-radius:6px 0 6px 6px;
    -moz-border-radius:6px 0 6px 6px;
    border-radius:6px 0 6px 6px;
}
.spacer {
    width:100%;
    min-height: 30px;
    height:30px;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}