@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$font: "Segoe UI", sans-serif;

$color-primary-0: #7C61A1;	// Main Primary color */
$color-primary-1: #CDC0DF;
$color-primary-2: #A28DC0;
$color-primary-3: #5B3D86;
$color-primary-4: #40226B;

$color-complement-0: #5A7E99;	// Main Complement color */
$color-complement-1: #BCCDDB;
$color-complement-2: #87A3B9;
$color-complement-3: #38607F;
$color-complement-4: #1F4765;


$linkColor: $color-primary-0;
$grayLighter: $color-complement-0;
$error: rgba(255,0,0,1);


// As RGBa codes */

$rgba-primary-0: rgba(124, 97,161,1);	// Main Primary color */
$rgba-primary-1: rgba(205,192,223,1);
$rgba-primary-2: rgba(162,141,192,1);
$rgba-primary-3: rgba( 91, 61,134,1);
$rgba-primary-4: rgba( 64, 34,107,1);

$rgba-complement-0: rgba( 90,126,153,1);	// Main Complement color */
$rgba-complement-1: rgba(188,205,219,1);
$rgba-complement-2: rgba(135,163,185,1);
$rgba-complement-3: rgba( 56, 96,127,1);
$rgba-complement-4: rgba( 31, 71,101,1);

@import "resources/assets/sass/creative.scss";
@import "resources/assets/sass/ffxiii-blog.scss";
@import "bower_components/jquery-sortable/source/css/jquery-sortable.css.sass";
@import "resources/assets/sass/sortable-table.css.scss";
