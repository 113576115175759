html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: $font;
}

hr {
    max-width: 50px;
    border-color: $color-primary-0;
    border-width: 3px;
}

hr.light {
    border-color: #fff;
}

a {
    color: $color-primary-0;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

a:hover,
a:focus {
    color: #7C61A1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font;
}

p {
    margin: 0px auto;
    font-size: 20px;
    line-height: 1.5;
}

.bg-primary {
    background-color: $color-primary-0;
}

.bg-dark {
    color: #fff;
    background-color: #222;
}

.text-faded {
    color: rgba(255,255,255,.7);
}

section {
    padding: 100px 0;
}
section.bg-primary{padding: 30px 0;}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

.navbar-default {
    border-color: rgba(34,34,34,.05);
    font-family: $font;
    background-color: #fff;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

.navbar-default .navbar-header .navbar-brand {
    text-transform: uppercase;
    font-family: $font;
    font-weight: 700;
    color: $color-primary-0;
}

.navbar-default .navbar-header .navbar-brand:hover,
.navbar-default .navbar-header .navbar-brand:focus {
    color: #eb3812;
}

.navbar-default .nav > li>a,
.navbar-default .nav>li>a:focus {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #222;
}

.navbar-default .nav > li>a:hover,
.navbar-default .nav>li>a:focus:hover {
    color: $color-primary-0;
}

.navbar-default .nav > li.active>a,
.navbar-default .nav>li.active>a:focus {
    color: $color-primary-0!important;
    background-color: transparent;
}

.navbar-default .nav > li.active>a:hover,
.navbar-default .nav>li.active>a:focus:hover {
    background-color: transparent;
}

@media(min-width:768px) {
    .navbar-default {
        border-color: rgba(255,255,255,.5);
        background-color: rgba(255,255,255,.5);
    }

    .navbar-default .navbar-header .navbar-brand {
        color: $color-primary-0;
    }

    .navbar-default .navbar-header .navbar-brand:hover,
    .navbar-default .navbar-header .navbar-brand:focus {
        color: $color-primary-2;
    }

    .navbar-default .nav > li>div.dropdown{padding: 5px;}


    .navbar-default .nav > li>a, .navbar-default .nav > li>div.dropdown,
    .navbar-default .nav>li>a:focus {
        color: $color-primary-2;
    }

    .navbar-default .nav > li>a:hover,
    .navbar-default .nav>li>a:focus:hover {
        color: $color-primary-0;
    }

    .navbar-default.affix {
        border-color: rgba(34,34,34,.05);
        background-color: #fff;
    }

    .navbar-default.affix .navbar-header .navbar-brand {
        font-size: 14px;
        color: $color-primary-0;
    }

    .navbar-default.affix .navbar-header .navbar-brand:hover,
    .navbar-default.affix .navbar-header .navbar-brand:focus {
        color: #eb3812;
    }

    .navbar-default.affix .nav > li>a,
    .navbar-default.affix .nav>li>a:focus {
        color: #222;
    }

    .navbar-default.affix .nav > li>a:hover,
    .navbar-default.affix .nav>li>a:focus:hover {
        color: $color-primary-0;
    }
}

header {
    position: relative;
    width: 100%;
    min-height: auto;
    text-align: center;
    color: #FFFFFF;
    background-image: url(/images/header-background.jpg);
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

header .header-content {
    position: relative;
    width: 100%;
    padding: 100px 15px;
    text-align: center;
}

header .header-content .header-content-inner h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
}

header .header-content .header-content-inner hr {
    margin: 30px auto;
}

header .header-content .header-content-inner p {
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255,255,255,.9);
    text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
}

@media(min-width:768px) {
    header {
        min-height: 100%;
    }

    header .header-content {
        position: absolute;
        top: 50%;
        padding: 0 50px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    header .header-content .header-content-inner {
        margin-right: auto;
        margin-left: auto;
        max-width: 1000px;
    }

    header .header-content .header-content-inner p {
        margin-right: auto;
        margin-left: auto;
        max-width: 80%;
        font-size: 18px;
    }
}

.section-heading {
    margin-top: 0;
}

.service-box {
    margin: 50px auto 0;
    max-width: 400px;
}

@media(min-width:992px) {
    .service-box {
        margin: 20px auto 0;
    }
}

.service-box p {
    margin-bottom: 0;
}

.portfolio-box {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 650px;
}

.portfolio-box .portfolio-box-caption {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    opacity: 0;
    background: rgba(240,95,64,.9);
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    padding: 0 15px;
    font-family: $font;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 18px;
}

.portfolio-box:hover .portfolio-box-caption {
    opacity: 1;
}

@media(min-width:768px) {
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
        font-size: 16px;
    }

    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        font-size: 22px;
    }
}

.call-to-action h2 {
    margin: 0 auto 20px;
}

.text-primary {
    color: $color-primary-0;
}

.no-gutter > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.btn-default {
    border-color: #fff;
    color: #222;
    background-color: #fff;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    border-color: #ededed;
    color: #222;
    background-color: #f2f2f2;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    border-color: #fff;
    background-color: #fff;
}

.btn-default .badge {
    color: #fff;
    background-color: #222;
}

.btn-primary {
    border-color: $color-primary-0;
    color: #fff;
    background-color: $color-primary-0;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    border-color: $color-primary-4;
    color: #fff;
    background-color: $color-primary-3;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: $color-primary-0;
    background-color: $color-primary-0;
}

.btn-primary .badge {
    color: $color-primary-0;
    background-color: #fff;
}

.btn {
    border: 0;
    text-transform: uppercase;
    font-family: $font;
    font-weight: 700;
}

.btn-xl {
    padding: 15px 30px;
}

::-moz-selection {
    text-shadow: none;
    color: #fff;
    background: #222;
}

::selection {
    text-shadow: none;
    color: #fff;
    background: #222;
}

img::selection {
    color: #fff;
    background: 0 0;
}

img::-moz-selection {
    color: #fff;
    background: 0 0;
}

body {
    webkit-tap-highlight-color: #222;
}
.btn {
    padding: 12px 20px;
}

.btn-default {
    border-color: $color-complement-3;
    color: #FFFFFF;
    background-color: $color-complement-0;
    transition: all .35s;
}
.bg-primary .section-heading {
    font-size:60px;
    text-transform: uppercase;
}

#services
{
    padding: 0;
}
#services .container {
    width:100%;
}
#services .container .col-lg-6{
    padding:0;
}
#services .container .col-lg-6 .row{margin: 0;}
.paradigm-title{
    background-color:#BCCDDB;
    width:100%;
    padding:20px;
    box-sizing:border-box;
    margin:0;
}

.article-image{
    display:block;
    width:100%;
    margin:0 auto 0 0;
    padding: 0;
}
#admin-title{padding-top:100px;}
.btn .glyphicon.pull-right{padding-left:10px;}
.btn .glyphicon.pull-left{padding-right:10px;}

.uploaded-image-thumbnail-outer
{
    position: relative;
    display: block;
    width: 120px;
    height: 120px;
    padding:0;
}
.uploaded-image-thumbnail
{
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
}
.top-buffer{padding-top:20px;}
.bottom-buffer{padding-bottom:20px;}
.uploaded-image-thumbnail-outer .glyphicon.glyphicon-remove{
    position:absolute;
    top:0;
    right:0;
    cursor:pointer;
    background-color: rgba(255,255,255,.5);
    z-index: 20;
    border-radius: 4px;
}
.paradigm-table{width:100%;border-collapse: collapse;}
.paradigm-table tr td:nth-child(even)
{
    box-sizing:border-box;
    background-color: $color-primary-2;
    padding: 10px 20px;
    color:#FFFFFF;
    font-weight: 700;
    font-size: 20px;
}
.paradigm-table tr td:nth-child(odd)
{
    box-sizing:border-box;
    background-color: $color-primary-4;
    padding:10px 20px;
    color:#FFFFFF;
    font-weight: 700;
    font-size: 20px;
}
.guide-content{
    font-size: 18px;
    line-height: 34px;
    box-sizing:border-box;
    display:block;
    width: 100%;
    padding: 20px;
    font-weight: 600;
}
.guide-content p{
    font-size: 18px;
    line-height: 34px;
    font-weight: 600;
}
.guide-content hr{max-width: 2000px;}
.panel-group .panel:first-child .panel-heading{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.panel-group .panel:last-child .panel-heading{
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom: 0;
}
.panel-group .panel{
    border:0;
    border-bottom: 1px solid $rgba-primary-3;
    border-radius:0;
}
.panel-group .panel:last-child{
    border-bottom: 0;
}
.panel-group .panel + .panel{margin:0 auto;}
.panel .panel-heading{
    border-radius:0;
    background-color: $rgba-complement-2;
}
.panel .panel-heading .panel-title{
    color:#FFFFFF;
    font-weight: 700;
    font-size: 19px;
}
.panel .panel-body{
    background-color: $rgba-complement-1;
}