.sorted_table tr {
  cursor: pointer; }
.sorted_table tr.placeholder {
  display: block;
  background: red;
  position: relative;
  margin: 0;
  padding: 0;
  border: none; }
.sorted_table tr.placeholder:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left-color: $error;
    margin-top: -5px;
    left: -5px;
    border-right: none; }